import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  Breadcrumbs,
  TextBox,
  QuickFacts,
  BannerLinks,
  BannerSmall,
  CallUs,
  ProjectPagination,
  Image
} from '../../components'

import boxes from '../../components/BannerLinks/DefaultBox'
import contacts from '../../components/CallUs/DefaultContacts'

import { generateBreadcrumbsLinks } from '../../utils/index'

import image4 from '../../assets/bannerlinks/bannerbox.eglo.pim.jpg'
import image5 from '../../assets/bannerlinks/berufsreise.bannerbox.jpg'
import header from '../../assets/header.dibk.jpg'

export const frontmatter = {
  title: 'Wer hilft Wie',
  slug: '/Projekte/Wer hilft Wie'
}

const Dioezese = () => {
  const links = generateBreadcrumbsLinks('/projekte/werhilftwie', 'Wer Hilft Wie')
  return (
    <Layout margin={false} color="white">
    <Helmet
      title="Wer Hilft Wie"
      meta={[
        {
          name: 'description',
          content:
            'Das Team der Landesvolksanwaltschaft hat gemeinsam mit der Telefonseelsorge Innsbruck im Jahr 2010 eine Suchmaschine entwickelt, die online rund 700 Einrichtungen im Sozial- und Behindertenbereich erfasst.'
        },
        {
          name: 'keywords',
          content:
            'werhilftwie holzweg, Projekt holzweg, Innsbruck, Webseite holzweg, wer hilft wie, whw, Hilfe'
        },
        {
          property: 'og:description',
          content:
            'Das Team der Landesvolksanwaltschaft hat gemeinsam mit der Telefonseelsorge Innsbruck im Jahr 2010 eine Suchmaschine entwickelt, die online rund 700 Einrichtungen im Sozial- und Behindertenbereich erfasst.'
        },
        {
          property: 'og:image',
          content: 'https://www.holzweg.com/static/header.dibk-644a28d3193a32ffd92ba0706dac2dd0.jpg'
        }
      ]}
    />
     <BannerSmall image={header}  />
      <Breadcrumbs
        links={links}
        backLinkAlias="Übersicht aller aktuellen Projekte"
      />
      <TextBox
        title={
          <div>
            Wer Hilft Wie <br /> Online-Suchmaschine
          </div>
        }
        text={
          <span>
            <h2></h2>
            <p>Das Team der Landesvolksanwaltschaft hat gemeinsam mit der Telefonseelsorge Innsbruck im Jahr 2010 eine Suchmaschine entwickelt, die online rund 700 Einrichtungen im Sozial- und Behindertenbereich erfasst.</p>
            <p>Durch Eingabe eines Begriffes in der Suchleiste können diese Einrichtungen abgerufen werden. Schwerpunkte wurden auf die einfache Bedienung und Übersichtlichkeit gelegt.</p> 
            <p>Präzisieren kann der User/die Userin die Eingabe mit 13 Überbegriffen sowie über die Auswahl eines Bezirkes.</p>
            <p>Die Suchmaschine wird von der Telefonseelsorge und vom Büro der Landesvolksanwältin gemeinsam gewartet und einmal im Jahr aktualisiert.</p>
            <p>Diese Seite wird sehr stark frequentiert und wurde im Berichtsjahr 2019 von 39.943 Usern und somit durchschnittlich von 3.329 Usern pro Monat oder 109 Usern pro Tag, aufgerufen.</p>
          </span>
        }
        buttons={[
          {link: 'https://www.werhilftwie-tirol.at', text: 'Website öffnen'},
        ]}
      />
      <QuickFacts
        facts={[
          { count: 40000, text: 'User jährlich' },
          { count: 700, text: 'Organistionen' },
          { count: 10, text: 'Jahre im Einsatz' }
        ]}
        color="orange"
      />
      <CallUs contacts={contacts} title="Gerne für Sie da" />
      <TextBox titleMods={["center"]}
        title="Weitere Projekte"
        text={false}
      />
      <ProjectPagination
      titleLeft="Berufsreise"
      imageLeft={image5}
      leftLink="/projekte/berufsreise"
      titleModsLeft={['black']}
      titleRight="EGLO PIM"
      imageRight={image4}
      rightLink="/projekte/eglo-pim"
      />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default Dioezese
